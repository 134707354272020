<footer class="site-footer footer-large footer-dark text-white footer-style1">


    <!-- FOOTER BLOCKES START -->
    <div class="footer-top bg-no-repeat bg-bottom-right"
        style="background-image:url(assets/images/background/footer-bg.png)">
        <div class="container">
            <div class="row">

                <div class="col-lg-5 col-md-12 col-sm-12">
                    <div class="footer-h-left">
                        <div class="widget widget_about">
                            <div class="logo-footer clearfix">
                                <a routerLink="/index"><img id="footerLogo1" src="assets/images/skl_logo.png"
                                        alt=""></a>
                            </div>
                        </div>
                        <div class="widget recent-posts-entry">
                            <ul class="widget_address">
                                <li><i class="fa fa-map-marker"></i>Cluj-Napoca, Borhanciului, nr 13</li>
                                <li><i class="fa fa-envelope"></i>office@skl.ro</li>
                                <li> <i class="fa fa-phone"></i>0740 010 252</li>
                                <li> <i class="fa fa-phone"></i>0745 777 585</li>
                                <li> <i class="fa fa-phone"></i>0723 000 585</li>
                            </ul>
                        </div>
                        <ul class="social-icons  wt-social-links footer-social-icon">
                            <li><a target="_blank"
                                    href="https://www.facebook.com/LocalBusinessServiceAndEngineering">Facebook</a></li>
                        </ul>
                    </div>

                </div>

                <div class="col-lg-7 col-md-12 col-sm-12">
                    <div class="row footer-h-right">
                        <div class="col-lg-5 col-md-4">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Link-uri utile</h3>
                                <ul>
                                    <li><a routerLink="/acasa">Acasă</a></li>
                                    <li><a routerLink="/despre-noi">Despre noi</a></li>
                                    <li><a routerLink="/servicii">Servicii</a></li>
                                    <li><a routerLink="/proiecte">Proiecte</a></li>
                                    <li><a routerLink="/cariera">Carieră</a></li>
                                    <li><a routerLink="/contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-7 col-md-8">
                            <div class="widget widget_services">
                                <h3 class="widget-title">Serviciile noastre</h3>
                                <ul *ngFor="let service of services">
                                    <li>
                                        <a [routerLink]="service.path">{{ service.lowercaseTitle }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <div class="footer-bottom">
        <div class="container">
            <div class="wt-footer-bot-left d-flex justify-content-between">
                <span class="copyrights-text">Copyright © 2024 <span class="site-text-primary"> SKL Technion
                        Solution</span></span>
                <ul class="copyrights-nav">
                    <li><a routerLink="/about/about1">Terms &amp; Condition</a></li>
                    <li><a routerLink="/about/about2">Privacy Policy</a></li>
                    <li><a routerLink="/contact">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>