import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ServicesService {

    all: ServiceDescription[] = [
        new ServiceDescription({
            title: "SISTEME FOTOVOLTAICE",
            lowercaseTitle: "Sisteme fotovoltaice",
            list: [
                "Industriale",
                "Rezidențiale",
                "Parcuri"
            ],
            icon: "assets/icons/solar.png",
            path: "/servicii/sisteme-fotovoltaice"
        }),

        new ServiceDescription({
            title: "INSTALAȚII ELECTRICE",
            lowercaseTitle: "Instalații electrice",
            list: [
                "Curenți tari și slabi, interioare și exterioare",
                "Iluminat de siguranță și antipanică",
                "Legare la pământ",
                "Paratrăsnet",
                "Automatizări electrice",
                "Tablouri electrice",
            ],
            icon: "assets/icons/electric.png",
            path: "/servicii/instalatii-electrice",
        }),
        new ServiceDescription({
            title: "SISTEME DE SECURITATE",
            lowercaseTitle: "Sisteme de securitate",
            list: [
                "Supraveghere video",
                "Detecție efracție",
                "Interfonie & videointerfonie",
                "Sisteme control acces",
            ],
            icon: "assets/icons/video.png",
            path: "/servicii/sisteme-de-securitate",
        }),
        new ServiceDescription({
            title: "MONITORIZARE ȘI MENTENANȚĂ",
            lowercaseTitle: "Monitorizare și mentenanță",
            list: [
                "Sisteme fotovoltaice",
                "Sisteme antiincendiu",
                "Sisteme supraveghere",
                "Generală a instalațiilor electrice",
            ],
            icon: "assets/icons/maintenance.png",
            path: "/servicii/monitorizare-si-mentenanta",
        }),
        new ServiceDescription({
            title: "PROIECTARE ȘI CONSULTANȚĂ",
            lowercaseTitle: "Proiectare și consultanță",
            list: [
                "Proiectare instalații curenți tari și slabi",
                "Consultanță tehnică și inginerie",
            ],
            icon: "assets/icons/design.png",
            path: "/servicii/proiectare-si-consultanta",
        }),
        new ServiceDescription({
            title: "SISTEME ANTIINCENDIU",
            lowercaseTitle: "Sisteme antiincendiu",
            list: [
                "Detecție și avertizare incendiu",
                "Desfumare",
            ],
            icon: "assets/icons/fire.png",
            path: "/servicii/sisteme-antiincendiu",
        }),
        new ServiceDescription({
            title: "EFICIENȚĂ ENERGETICĂ",
            lowercaseTitle: "Eficiență energetică",
            list: [
                "Audit energetic",
                "Eficiență energetică (ZECO)",
                "Sisteme BMS și tehnologii SMART",
            ],
            icon: "assets/icons/energy.png",
            path: "/servicii/eficienta-energetica",
        }),
    ]
}

export class ServiceDescription {
    title: string;
    lowercaseTitle: string;
    description: string;
    icon: string;
    list: string[];
    isActive: boolean = true;
    path: string = "";

    public constructor(init?: Partial<ServiceDescription>) {
        Object.assign(this, init);
    }
}
