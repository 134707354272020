import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-despre-noi',
  templateUrl: './despre-noi.component.html',
  styleUrls: ['./despre-noi.component.css']
})
export class DespreNoiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/8.jpg",
    title: "DESPRE NOI",
    // crumb: "Detalii job"
  }

  // data = {
  //   work: [
  //     {icon: , title: }
  //   ]
  // }

  data = {
    title: "What We do",
    subtitle: "Providing Full Range of High Services Solution Worldwide",
    description: "Progressively maintain extensive infomediaries via extensible niches. Capitalize on low hanging fruit to identify a ballpark value added is activity to beta test. Override the igital divide",
    image: "assets/images/what-we/pic1.jpg",
    experience: {
      title: "Team Working Dedicatedly",
      description: "We have 26+ years of experience with providing wide area of specialty services works listed below"
    },
    work: [
      {
        icon: "../../../../assets/images/despre/consultanta.png",
        title: "CONSULTANȚĂ & INGINERIE",
        description: "Oferim consultanță și propunem soluții fiabile care vin în întâmpinarea nevoilor clientului."
      },
      {
        icon: "../../../../assets/images/despre/proiectare.png",
        title: "PROIECTARE & BUGETARE",
        description: "Realizăm servicii specializate de proiectare și bugetarea întregului sistem astfel încât această soluție să se integreze în compania dumneavoastră."
      },
      {
        icon: "../../../../assets/images/despre/implementare.png",
        title: "IMPLEMENTARE & INTEGRARE",
        description: "Realizăm implementarea “la cheie”: instalarea, parametrizarea, integrarea în rețea și punerea în funcțiune a sistemului."
      },
      {
        icon: "../../../../assets/images/despre/mentenanta.png",
        title: "MENTENANȚĂ",
        description: "Competență profesională pentru mentenanța oricărui tip de sistem de instalații civile, industriale sau energetice."
      }
    ],
    specifications: [
      {
        title: "Atestări / Autorizări / Certificări / Licențe",
        list: [
          "<strong>Atestat ANRE de tip IIA si IIB, nr. 201815001 / 05.12.2018</strong> – pentru proiectare și execuție de instalații electrice de joasă și medie tensiune, la tensiunea nominală de 0,4 kV.",
          "<strong>Atestat ANRE de tip B, nr. 13387 / 25.06.2018</strong> – pentru proiectare și executare de instalații electrice exterioare / interioare pentru incinte / construcții civile și industriale, branșamente aeriene și subterane, la tensiunea nominală de 0.4 kV.",
          "<strong>Autorizatie B-0314 / 09.12.2020</strong> – pentru instalare și întreținere a sistemelor și instalațiilor de semnalizare, alarmare și alertare în caz de incendiu.",
          "<strong>Autorizatie B-0442 / 12.02.2021</strong> – pentru proiectarea sistemelor și instalațiilor de semnalizare, alarmare și alertare în caz de incendiu.",
          "<strong>Aurorizatie B-0303 / 26.11.2020</strong> – pentru proiectarea sistemelor și instalațiilor de limitare și stingere a incendiilor.",
          "<strong>Autorizatie B-0278 / 19.11.2020</strong> – pentru proiectarea sistemelor și instalațiilor de ventilare pentru evacuarea fumului și gazelor fierbinți, cu excepția celor de tip natural organizat.",
          "<strong>Licență de functionare nr. 5490/T din 16.06.2022</strong> – pentru proiectare, instalare, modificare sau întreținere a componentelor sau sistemelor de alarmare împotriva efracției.",
          "<strong>Certificat ISO 9001 : 2015 nr. 1255/SKL09A</strong> – Sistemul de Management al Calității.",
          "<strong>Certificat ISO 14001 : 2015 nr. 1255/SKL14A</strong> – Sistemul de Management al Mediului"
        ]
      }
    ]
  }


}
