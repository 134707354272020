import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page-project-grid',
  templateUrl: './page-project-grid.component.html',
  styleUrls: ['./page-project-grid.component.css']
})
export class PageProjectGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  banner = {
    background: "assets/images/banner/5.jpg",
    title: "PROIECTE",
    // crumb: "Project Grid"
  }
}
