import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-about1',
  templateUrl: './page-about1.component.html',
  styleUrls: ['./page-about1.component.css']
})
export class PageAbout1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  banner = {
    background: "assets/images/banner/3.jpg",
    title: "About Us",
    crumb: "About 1"
  }

  about = {
    title: "Welcome to industro",
    subtitle: "We Are Here to Increase Your Knowledge With Experience",
    image1: "assets/images/colarge/s2.jpg",
    image2: "assets/images/colarge/s1.jpg",
    since: "2011",
    description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit. a ballpark value added is activity to beta test. Override the digital divide with additional click throughs from fruit to identify a ballpark value added.",
    services: [
      {
        value: "Quality Control System , 100% Satisfaction Guarantee"
      },
      {
        value: "Unrivalled Workmanship, Professional and Qualified"
      },
      {
        value: "Environmental Sensitivity, Personalised Solutions"
      }
    ]
  }

  services = {
    title: "The Best Industry services",
    subtitle: "High Performance Services For Multiple Insdustries And Technologies!",
    services: [
      {
        title1: "Oil &",
        title2: "Gas Engineering",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-industry",
        count: "01",
        pageLink: "s-oilgas"
      },
      {
        title1: "Mechanical",
        title2: "Engineering",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-conveyor",
        count: "02",
        pageLink: "s-mechanical"
      },
      {
        title1: "Automotive",
        title2: "Manufacturing",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-robotic-arm",
        count: "03",
        pageLink: "s-automotive"
      },
      {
        title1: "Chemical",
        title2: "Research",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-oil",
        count: "04",
        pageLink: "s-chemical"
      },
      {
        title1: "Agricultural",
        title2: "Automation",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-scythe",
        count: "05",
        pageLink: "s-agricultural"
      },
      {
        title1: "Civil",
        title2: "Engineering",
        description: "Progressively maintain extensive infomediaries via extensible nich. Capitalize on low hanging fruit.",
        icon: "flaticon-helmet",
        count: "06",
        pageLink: "s-civil"
      }
    ]
  }

  video = {
    title: "All Solutions",
    subtitle: "Get A Solution For All Industries",
    videolink: "https://www.youtube.com/watch?v=fgExvIUYg5w",
    solutions: [
      {
        count: "280",
        title: "Proiecte complete"
      },
      {
        count: "250",
        title: "Clienti"
      },
      {
        count: "25",
        title: "Angajati"
      },
      {
        count: "26",
        title: "Winning Awards"
      }
    ],
    description: {
      quote: "Many of Our SELF registered employees are requested an main preferred temporary staff when all service",
      quoteby: "Farnandoz Biki, CEO"
    }
  }

  team = {
    title: "Our Experts",
    subtitle: "We will serve you with the best of our capacity by expert team",
    bgImage: "assets/images/background/bg-map.png",
    members: [
      {
        image: "assets/images/team/pic1.jpg",
        name: "Malcolm Franzcrip",
        post: "Contractor"
      },
      {
        image: "assets/images/team/pic2.jpg",
        name: "Froster Collings",
        post: "Contractor"
      },
      {
        image: "assets/images/team/pic3.jpg",
        name: "Melena Marshall",
        post: "Contractor"
      }
    ]
  }

  clients = {
    bgcolor: "bg-gray",
    clients: [
      "assets/images/client-logo/w1.png",
      "assets/images/client-logo/w2.png",
      "assets/images/client-logo/w3.png",
      "assets/images/client-logo/w4.png",
      "assets/images/client-logo/w5.png",
      "assets/images/client-logo/w6.png",
      "assets/images/client-logo/w1.png",
      "assets/images/client-logo/w2.png",
      "assets/images/client-logo/w3.png",
      "assets/images/client-logo/w4.png",
      "assets/images/client-logo/w5.png",
      "assets/images/client-logo/w6.png",
      "assets/images/client-logo/w1.png",
      "assets/images/client-logo/w2.png",
      "assets/images/client-logo/w3.png",
      "assets/images/client-logo/w4.png",
      "assets/images/client-logo/w5.png",
      "assets/images/client-logo/w6.png"
    ]
  }
}
